// APP Version
export const APP_VERSION = process.env.REACT_APP_VERSION

// API URL
export const API_URL = process.env.REACT_APP_API_URL

// Image Hosting
export const IMAGES_DOMAIN = process.env.REACT_APP_IMAGE_DOMAIN

// Google Maps Services
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY
export const GOOGLE_MAPS_ID = process.env.REACT_APP_GOOGLE_MAPS_ID
export const GOOGLE_PLACES_LIBRARY = ['maps', 'places', 'marker']

// Localization Services
export const LOCIZE_API_KEY = process.env.REACT_APP_LOCIZE_API_KEY
export const LOCIZE_PROJECT_ID = process.env.REACT_APP_LOCIZE_PROJECT_ID

// Error Tracking & Monitoring
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT

// Real User Monitoring (Production Only)
export const RUM_APP_ID = process.env.REACT_APP_RUM_APP_ID
export const RUM_CLIENT_TOKEN = process.env.REACT_APP_RUM_CLIENT_TOKEN
